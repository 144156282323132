import type {LinksFunction} from '@remix-run/server-runtime';
import {Outlet, useMatches} from '@remix-run/react';

import {preloadFont} from '@/utils/remix/preload-font';
import {Theme} from '@/enums';
import type {Handle} from '@/types';
import Provider from '@/components/shared/Page/Provider';
import blogStylesheet from '@/stylesheets/blog/blog.css?url';

export const links: LinksFunction = () => {
  return [
    preloadFont('ShopifySans--medium.woff2'),
    preloadFont('ShopifySans--bold.woff2'),
    preloadFont('ShopifySans--extrabold.woff2'),
    {rel: 'stylesheet', href: blogStylesheet},
  ];
};
export const handle: Handle = {
  theme: Theme.BrochureV2,
  enableGtm: true,
};

export default function Layout() {
  const matches = useMatches();
  let merged = {};
  for (const match of matches) {
    if (match.data) {
      merged = {...merged, ...match.data};
    }
  }

  return (
    <Provider data={merged}>
      <Outlet />
    </Provider>
  );
}
